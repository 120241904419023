.img-container{
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}

.img-container img{
    width: 15%;
    aspect-ratio: 3/2;
    object-fit: contain;

}