@media screen and (max-width: 1200px){
    #service-card{
        width: 95%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }
}

@media screen and (max-width: 800px){
    #service-card{
        width: 95%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
}

@media screen and (max-width: 600px){
    #service-card{
        width: 95%;
        border: 0px solid red;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: auto;
    }
}