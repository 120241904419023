/* .App {
    font-family: sans-serif;
    text-align: center;
    padding: 10px;
  } */

.testimonialContainer {
    background-color: white;
    width: 90%;
    /* height: 100%; */
    margin: auto;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.testimonialHeader {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.imageContainer {
    background-size: cover;
    border-radius: 50%;
    width: 80px;
    /* height: 70px; */
    background-color: grey;
}

.testimonialBody {
    text-align: justify;
    padding-left: 8px;
    padding-right: 8px;
}

.iconContainer {
    width: 30px;
    justify-self: end;
    margin-left: auto;
}

.icon {
    fill: rgb(25, 118, 210);
}

.nameContainer {
    margin-left: 10px;
    text-align: left;
}

.testimonialBodyText {
    font-size: 1rem;
}

p {
    margin: 0.2rem;
    font-size: 0.9rem;
}

h4 {
    margin: 0;
}