:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
  
    color-scheme: light dark;
    color: rgba(255, 255, 255, 0.87);
    background-color: #242424;
  
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  
  a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
  }
  a:hover {
    color: #535bf2;
  }
  .avatar-card{
    width: 60%;
    margin: auto;
  }
  .avatar{
    width: 200px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
  }
  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
  .designation{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #0238ad;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
  .website{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #F5F5F5;
    margin-top: 0px;
    padding-top: 0px;
  }
  
  .remove-space{
    margin-top: 0px;
    padding-top: 0px;
  }
  .emailIcon{
    width: 19px;
    height: 19px;
    padding-right: 4px;
  }
  button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
    width: 100%;
  }
  button:hover {
    border-color: #646cff;
  }
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
  
  .main-card{
    width: 50%;
    background: rgb(197, 205, 50);
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .w317{
    width: 317px;
  }
  
  @media (prefers-color-scheme: light) {
    :root {
      color: #213547;
      background-color: #ffffff;
    }
    a:hover {
      color: #747bff;
    }
    button {
      background-color: #f9f9f9;
    }
  }

  @media screen and (max-width: 450px){
    .main-card{
        width: 90%;
      }
}
  