@media screen and (max-width: 950px){
    #nav-search{
        display: none;
    }
}

@media screen and (max-width: 900px){
    #nav-search{
        display: none;
    }
}