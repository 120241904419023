.about-parent{
    /* height: 100vh;
    width: 100vw; */
    /* scroll-snap-type: y mandatory; */
    /* overflow-x: hidden; */

    /* overflow-y: scroll; */

}

.about-child{
    /* scroll-snap-align: start; */
    /* height: 100vh;
    width: 100vw; */
}

.about-child-one{
    margin-top: 60px;
}