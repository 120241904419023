#top-right-container{
    position: relative;
}

#left-top-box{
    width: 300px;
    height: 130px;
    /* border: 1px solid red; */
    position: absolute;
    top: 50px;
    left: 60px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    text-align: left;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#left-top-box:hover{
    width: 310px;
    height: 140px;
    cursor: pointer;
}

a{
    font-size: 10px;
    color: blue;
}

#right-top-box{
    width: 300px;
    height: 130px;
    /* border: 1px solid red; */
    position: absolute;
    top: 0px;
    right: 120px;
    border-radius: 8px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    text-align: left;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#right-top-box:hover{
    width: 310px;
    height: 140px;
    cursor: pointer;
}

#right-bottom-box{
    width: 300px;
    height: 130px;
    /* border: 1px solid red; */
    position: absolute;
    bottom: 8px;
    right: 160px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    text-align: left;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#right-bottom-box:hover{
    width: 310px;
    height: 140px;
    cursor: pointer;
}

#left-bottom-box{
    width: 300px;
    height: 130px;
    /* border: 1px solid red; */
    position: absolute;
    bottom: -30px;
    left: 10px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    text-align: left;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#left-bottom-box:hover{
    width: 310px;
    height: 140px;
    cursor: pointer;
}


/* .main-container{
    display: flex;
    border: 1px solid red;
} */

@media screen and (max-width: 1320px){
    #top-left-container{
        width: 37% !important;
        border: 0px solid green;
    }
    #top-right-container{
        width: 64% !important;
    }
    #top-left-container-heading{
        width: 60%;
    }
    #left-top-box{
        width: 220px;
        height: 120px;
    }
    
    #left-top-box:hover{
        width: 230px;
        height: 130px;
    }
    
    #right-top-box{
        width: 220px;
        height: 120px;
    }
    
    #right-top-box:hover{
        width: 230px;
        height: 130px;
    }
    
    #right-bottom-box{
        width: 220px;
        height: 120px;
    }
    
    #right-bottom-box:hover{
        width: 230px;
        height: 130px;
    }
    
    #left-bottom-box{
        width: 220px;
        height: 120px;
    }
    
    #left-bottom-box:hover{
        width: 230px;
        height: 130px;
    }
}

@media screen and (max-width: 1100px){
    #top-left-container{
        display: block;
        width: 90% !important;
    }
    #top-right-container{
        display: none;
    }
    #top-left-container-heading{
        width: 60%;
    }
    #top-white-space{
        width: 500px;
        left: 195px;
    }
}

@media screen and (max-width: 950px){
    #top-white-space{
        width: 450px;
        left: 195px;
    }
}

@media screen and (max-width: 900px){
    #top-white-space{
        display: none;
    }
}

@media screen and (max-width: 450px){
   #top-left-container{
    width: 100%;
    border: 0px solid red;
    margin-left: -90px;
   }
   #top-left-container-heading{
    width: 70%;
}
}